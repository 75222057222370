import { createWidget } from "discourse/widgets/widget";

// glimmer-post-stream: has glimmer version
export default createWidget("post-placeholder", {
  tagName: "article.placeholder",
  template: function (attrs, state) {
    var _r = [];
    _r.push("\n    ");
    var _a0 = [];
    _a0.push("\n      ");
    var _a1 = [];
    _a1.push("\n        ");
    var _a2 = [];
    _a1.push(virtualDom.h('div', {
      "className": "placeholder-avatar placeholder-animation",
      "attributes": {}
    }, _a2));
    _a1.push("\n      ");
    _a0.push(virtualDom.h('div', {
      "className": "topic-avatar",
      "attributes": {}
    }, _a1));
    _a0.push("\n      ");
    var _a3 = [];
    _a3.push("\n        ");
    var _a4 = [];
    _a3.push(virtualDom.h('div', {
      "className": "placeholder-text placeholder-animation",
      "attributes": {}
    }, _a4));
    _a3.push("\n        ");
    var _a5 = [];
    _a3.push(virtualDom.h('div', {
      "className": "placeholder-text placeholder-animation",
      "attributes": {}
    }, _a5));
    _a3.push("\n        ");
    var _a6 = [];
    _a3.push(virtualDom.h('div', {
      "className": "placeholder-text placeholder-animation",
      "attributes": {}
    }, _a6));
    _a3.push("\n      ");
    _a0.push(virtualDom.h('div', {
      "className": "topic-body",
      "attributes": {}
    }, _a3));
    _a0.push("\n    ");
    _r.push(virtualDom.h('div', {
      "className": "row",
      "attributes": {}
    }, _a0));
    _r.push("\n  ");
    return _r;
  }
});