import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import willDestroy from "@ember/render-modifiers/modifiers/will-destroy";
import { waitForPromise } from "@ember/test-waiters";
import { create } from "virtual-dom";
import DButton from "discourse/components/d-button";
import DModal from "discourse/components/d-modal";
import { iconNode } from "discourse/lib/icon-library";
import { i18n } from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class JsonSchemaEditorModal extends Component {
  static #_ = (() => dt7948.g(this.prototype, "editor", [tracked], function () {
    return null;
  }))();
  #editor = (() => (dt7948.i(this, "editor"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "value", [tracked], function () {
    return this.args.model.value;
  }))();
  #value = (() => (dt7948.i(this, "value"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "flash", [tracked]))();
  #flash = (() => (dt7948.i(this, "flash"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "flashType", [tracked]))();
  #flashType = (() => (dt7948.i(this, "flashType"), void 0))();
  get settingName() {
    return this.args.model.settingName.replace(/\_/g, " ");
  }
  teardownJsonEditor() {
    this.editor?.destroy();
  }
  static #_5 = (() => dt7948.n(this.prototype, "teardownJsonEditor", [action]))();
  saveChanges() {
    const errors = this.editor.validate();
    if (!errors.length) {
      this.value = JSON.stringify(this.editor.getValue());
      this.args.model.updateValue(this.value);
      this.args.closeModal();
    } else {
      this.flash = errors.mapBy("message").join("\n");
      this.flashType = "error";
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "saveChanges", [action]))();
  async buildJsonEditor(element) {
    const promise = import("@json-editor/json-editor");
    waitForPromise(promise);
    const {
      JSONEditor
    } = await promise;
    JSONEditor.defaults.options.theme = "barebones";
    JSONEditor.defaults.iconlibs = {
      discourseIcons: DiscourseJsonSchemaEditorIconlib
    };
    JSONEditor.defaults.options.iconlib = "discourseIcons";
    this.editor = new JSONEditor(element, {
      schema: this.args.model.jsonSchema,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      disable_array_reorder: false,
      disable_array_copy: false,
      enable_array_copy: true,
      disable_edit_json: true,
      disable_properties: true,
      disable_collapse: false,
      show_errors: "never",
      startval: this.value ? JSON.parse(this.value) : null
    });
  }
  static #_7 = (() => dt7948.n(this.prototype, "buildJsonEditor", [action]))();
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DModal
        @flash={{this.flash}}
        @flashType={{this.flashType}}
        @closeModal={{@closeModal}}
        @title={{i18n
          "admin.site_settings.json_schema.modal_title"
          name=@model.settingName
        }}
        @inline={{@inline}}
        class="json-schema-editor-modal"
      >
        <:body>
          <div
            id="json-editor-holder"
            {{didInsert this.buildJsonEditor}}
            {{willDestroy this.teardownJsonEditor}}
          ></div>
        </:body>
  
        <:footer>
          <DButton
            @action={{this.saveChanges}}
            @label="save"
            class="btn-primary"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "hOGVrMuw",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"json-schema-editor-modal\"]],[[\"@flash\",\"@flashType\",\"@closeModal\",\"@title\",\"@inline\"],[[30,0,[\"flash\"]],[30,0,[\"flashType\"]],[30,1],[28,[32,1],[\"admin.site_settings.json_schema.modal_title\"],[[\"name\"],[[30,2,[\"settingName\"]]]]],[30,3]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[11,0],[24,1,\"json-editor-holder\"],[4,[32,2],[[30,0,[\"buildJsonEditor\"]]],null],[4,[32,3],[[30,0,[\"teardownJsonEditor\"]]],null],[12],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,4],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"saveChanges\"]],\"save\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\",\"@inline\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/modal/json-schema-editor.js",
    "scope": () => [DModal, i18n, didInsert, willDestroy, DButton],
    "isStrictMode": true
  }), this))();
}
class DiscourseJsonSchemaEditorIconlib {
  constructor() {
    this.mapping = {
      delete: "trash-can",
      add: "plus",
      moveup: "arrow-up",
      movedown: "arrow-down",
      moveleft: "chevron-left",
      moveright: "chevron-right",
      copy: "copy",
      collapse: "chevron-down",
      expand: "chevron-up"
    };
  }
  getIcon(key) {
    if (!this.mapping[key]) {
      return;
    }
    return create(iconNode(this.mapping[key]));
  }
}