import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { alias, or, readOnly } from "@ember/object/computed";
import { ajax } from "discourse/lib/ajax";
import discourseComputed from "discourse/lib/decorators";
import getURL from "discourse/lib/get-url";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import PasswordValidationHelper from "discourse/lib/password-validation-helper";
import DiscourseURL, { userPath } from "discourse/lib/url";
import { getWebauthnCredential } from "discourse/lib/webauthn";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import { i18n } from "discourse-i18n";
export default class PasswordResetController extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "accountPassword", [tracked]))();
  #accountPassword = (() => (dt7948.i(this, "accountPassword"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "isDeveloper", [alias("model.is_developer")]))();
  #isDeveloper = (() => (dt7948.i(this, "isDeveloper"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "admin", [alias("model.admin")]))();
  #admin = (() => (dt7948.i(this, "admin"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "secondFactorRequired", [alias("model.second_factor_required")]))();
  #secondFactorRequired = (() => (dt7948.i(this, "secondFactorRequired"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "securityKeyRequired", [alias("model.security_key_required")]))();
  #securityKeyRequired = (() => (dt7948.i(this, "securityKeyRequired"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "backupEnabled", [alias("model.backup_enabled")]))();
  #backupEnabled = (() => (dt7948.i(this, "backupEnabled"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "securityKeyOrSecondFactorRequired", [or("model.second_factor_required", "model.security_key_required")]))();
  #securityKeyOrSecondFactorRequired = (() => (dt7948.i(this, "securityKeyOrSecondFactorRequired"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "otherMethodAllowed", [readOnly("model.multiple_second_factor_methods")]))();
  #otherMethodAllowed = (() => (dt7948.i(this, "otherMethodAllowed"), void 0))();
  passwordRequired = true;
  errorMessage = null;
  successMessage = null;
  requiresApproval = false;
  redirected = false;
  maskPassword = true;
  passwordValidationHelper = (() => new PasswordValidationHelper(this))();
  isLoading = false;
  lockImageUrl = (() => getURL("/images/lock.svg"))();
  displaySecurityKeyForm(securityKeyRequired, selectedSecondFactorMethod) {
    return securityKeyRequired && selectedSecondFactorMethod === SECOND_FACTOR_METHODS.SECURITY_KEY;
  }
  static #_9 = (() => dt7948.n(this.prototype, "displaySecurityKeyForm", [discourseComputed("securityKeyRequired", "selectedSecondFactorMethod")]))();
  initSelectedSecondFactorMethod() {
    if (this.model.security_key_required) {
      this.set("selectedSecondFactorMethod", SECOND_FACTOR_METHODS.SECURITY_KEY);
    } else if (this.model.second_factor_required) {
      this.set("selectedSecondFactorMethod", SECOND_FACTOR_METHODS.TOTP);
    } else if (this.model.backup_enabled) {
      this.set("selectedSecondFactorMethod", SECOND_FACTOR_METHODS.BACKUP_CODE);
    }
  }
  get passwordValidation() {
    return this.passwordValidationHelper.passwordValidation;
  }
  continueButtonText() {
    return i18n("password_reset.continue", {
      site_name: this.siteSettings.title
    });
  }
  static #_10 = (() => dt7948.n(this.prototype, "continueButtonText", [discourseComputed()]))();
  redirectHref(redirectTo) {
    return getURL(redirectTo || "/");
  }
  static #_11 = (() => dt7948.n(this.prototype, "redirectHref", [discourseComputed("redirectTo")]))();
  get showPasswordValidation() {
    return this.passwordValidation.ok || this.passwordValidation.reason;
  }
  done(event) {
    if (wantsNewWindow(event)) {
      return;
    }
    event.preventDefault();
    this.set("redirected", true);
    DiscourseURL.redirectTo(this.redirectTo || "/");
  }
  static #_12 = (() => dt7948.n(this.prototype, "done", [action]))();
  togglePasswordMask() {
    this.toggleProperty("maskPassword");
  }
  static #_13 = (() => dt7948.n(this.prototype, "togglePasswordMask", [action]))();
  async submit() {
    try {
      this.set("isLoading", true);
      const result = await ajax({
        url: userPath(`password-reset/${this.get("model.token")}.json`),
        type: "PUT",
        data: {
          password: this.accountPassword,
          second_factor_token: this.securityKeyCredential || this.secondFactorToken,
          second_factor_method: this.selectedSecondFactorMethod,
          timezone: moment.tz.guess()
        }
      });
      if (result.success) {
        this.set("successMessage", result.message);
        this.set("redirectTo", result.redirect_to);
        if (result.requires_approval) {
          this.set("requiresApproval", true);
        } else {
          this.set("redirected", true);
          DiscourseURL.redirectTo(result.redirect_to || "/");
        }
      } else {
        if (result.errors.security_keys || result.errors.user_second_factors) {
          this.setProperties({
            secondFactorRequired: this.secondFactorRequired,
            securityKeyRequired: this.securityKeyRequired,
            password: null,
            errorMessage: result.message
          });
        } else if (this.secondFactorRequired || this.securityKeyRequired) {
          this.setProperties({
            secondFactorRequired: false,
            securityKeyRequired: false,
            errorMessage: null
          });
        } else if (result.errors?.["user_password.password"]?.length > 0) {
          this.passwordValidationHelper.rejectedPasswords.push(this.accountPassword);
          this.passwordValidationHelper.rejectedPasswordsMessages.set(this.accountPassword, (result.friendly_messages || []).join("\n"));
        }
        if (result.message) {
          this.set("errorMessage", result.message);
        }
      }
    } catch (e) {
      if (e.jqXHR?.status === 429) {
        this.set("errorMessage", i18n("user.second_factor.rate_limit"));
      } else {
        throw new Error(e);
      }
    } finally {
      this.set("isLoading", false);
    }
  }
  static #_14 = (() => dt7948.n(this.prototype, "submit", [action]))();
  authenticateSecurityKey() {
    this.set("selectedSecondFactorMethod", SECOND_FACTOR_METHODS.SECURITY_KEY);
    getWebauthnCredential(this.model.challenge, this.model.allowed_credential_ids, credentialData => {
      this.set("securityKeyCredential", credentialData);
      this.send("submit");
    }, errorMessage => {
      this.setProperties({
        securityKeyRequired: true,
        password: null,
        errorMessage
      });
    });
  }
  static #_15 = (() => dt7948.n(this.prototype, "authenticateSecurityKey", [action]))();
}