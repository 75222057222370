import { createWidget } from "discourse/widgets/widget";
createWidget("menu-panel", {
  tagName: "div.menu-panel",
  template: function (attrs, state) {
    var _r = [];
    _r.push("\n    ");
    var _a0 = [];
    _a0.push("\n      ");
    var _a1 = [];
    _a1.push("\n        ");
    _a1.push(this.attrs.contents());
    _a1.push("\n      ");
    _a0.push(virtualDom.h('div', {
      "className": "panel-body-contents",
      "attributes": {}
    }, _a1));
    _a0.push("\n    ");
    _r.push(virtualDom.h('div', {
      "className": "panel-body",
      "attributes": {}
    }, _a0));
    _r.push("\n  ");
    return _r;
  },
  buildAttributes(attrs) {
    if (attrs.maxWidth) {
      return {
        "data-max-width": attrs.maxWidth
      };
    }
  }
});