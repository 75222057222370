import Component from "@glimmer/component";
import { service } from "@ember/service";
import { getURLWithCDN } from "discourse/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DStyles extends Component {
  static #_ = (() => dt7948.g(this.prototype, "session", [service]))();
  #session = (() => (dt7948.i(this, "session"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "interfaceColor", [service]))();
  #interfaceColor = (() => (dt7948.i(this, "interfaceColor"), void 0))();
  get categoryColors() {
    return [":root {", ...this.site.categories.map(category => `--category-${category.id}-color: #${category.color};`), "}"].join("\n");
  }
  get categoryBackgrounds() {
    let css = [];
    const darkCss = [];
    this.site.categories.forEach(category => {
      const lightUrl = category.uploaded_background?.url;
      const darkUrl = this.session.defaultColorSchemeIsDark || this.session.darkModeAvailable ? category.uploaded_background_dark?.url : null;
      const defaultUrl = darkUrl && this.session.defaultColorSchemeIsDark ? darkUrl : lightUrl;
      if (defaultUrl) {
        const url = getURLWithCDN(defaultUrl);
        css.push(`body.category-${category.fullSlug} { background-image: url(${url}); }`);
      }
      if (darkUrl && defaultUrl !== darkUrl) {
        const url = getURLWithCDN(darkUrl);
        darkCss.push(`body.category-${category.fullSlug} { background-image: url(${url}); }`);
      }
    });
    if (darkCss.length > 0) {
      if (this.interfaceColor.darkModeForced) {
        css = darkCss;
      } else if (!this.interfaceColor.lightModeForced) {
        css.push("@media (prefers-color-scheme: dark) {", ...darkCss, "}");
      }
    }
    return css.join("\n");
  }
  get categoryBadges() {
    const css = [];
    this.site.categories.forEach(category => {
      css.push(`.badge-category[data-category-id="${category.id}"] { ` + `--category-badge-color: var(--category-${category.id}-color); ` + `--category-badge-text-color: #${category.textColor}; ` + `}`);
      if (category.isParent) {
        css.push(`.badge-category[data-parent-category-id="${category.id}"] { ` + `--parent-category-badge-color: var(--category-${category.id}-color); ` + `}`);
      }
    });
    return css.join("\n");
  }
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{! template-lint-disable no-forbidden-elements }}
      <style id="d-styles">
        {{#if this.site.categories}}
          {{this.categoryColors}}
          {{this.categoryBackgrounds}}
          {{this.categoryBadges}}
        {{/if}}
      </style>
    
  */
  {
    "id": "ubSv2iSd",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[10,\"style\"],[14,1,\"d-styles\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"categories\"]],[[[1,\"        \"],[1,[30,0,[\"categoryColors\"]]],[1,\"\\n        \"],[1,[30,0,[\"categoryBackgrounds\"]]],[1,\"\\n        \"],[1,[30,0,[\"categoryBadges\"]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-styles.js",
    "isStrictMode": true
  }), this))();
}