import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import copyText from "discourse/lib/copy-text";
import { slugify, toAsciiPrintable } from "discourse/lib/utilities";
import { i18n } from "discourse-i18n";
// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
    return String.fromCharCode("0x" + p1);
  }));
}
export default class BackupCodes extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  get siteTitleSlug() {
    const title = this.siteSettings.title;
    const convertedTitle = toAsciiPrintable(title, "discourse");
    return slugify(convertedTitle);
  }
  get base64BackupCode() {
    return b64EncodeUnicode(this.formattedBackupCodes);
  }
  get formattedBackupCodes() {
    if (!this.args.backupCodes) {
      return null;
    }
    return this.args.backupCodes.join("\r\n").trim();
  }
  copyToClipboard() {
    this._selectAllBackupCodes();
    const copied = copyText("", this.backupCodesArea);
    this.args.copyBackupCode(copied);
  }
  static #_2 = (() => dt7948.n(this.prototype, "copyToClipboard", [action]))();
  registerBackupCodesArea(element) {
    this.backupCodesArea = element;
    element.style.height = element.scrollHeight;
  }
  static #_3 = (() => dt7948.n(this.prototype, "registerBackupCodesArea", [action]))();
  _selectAllBackupCodes() {
    this.backupCodesArea.focus();
    this.backupCodesArea.setSelectionRange(0, this.formattedBackupCodes.length);
  }
  static #_4 = (() => dt7948.n(this.prototype, "_selectAllBackupCodes", [action]))();
  static #_5 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="backup-codes">
        <div class="wrapper">
          <textarea
            id="backupCodes"
            class="backup-codes-area"
            rows="10"
            readonly
            {{didInsert this.registerBackupCodesArea}}
            {{on "click" this._selectAllBackupCodes}}
          >{{this.formattedBackupCodes}}</textarea>
  
          <div class="controls">
            <DButton
              @action={{this.copyToClipboard}}
              @icon="copy"
              @ariaLabel="user.second_factor_backup.copy_to_clipboard"
              @title="user.second_factor_backup.copy_to_clipboard"
              class="backup-codes-copy-btn"
            />
  
            <DButton
              download="{{this.siteTitleSlug}}-backup-codes.txt"
              class="backup-codes-download-btn"
              aria-label={{i18n
                "user.second_factor_backup.download_backup_codes"
              }}
              title={{i18n "user.second_factor_backup.download_backup_codes"}}
              rel="noopener noreferrer"
              target="_blank"
              @href="data:application/octet-stream;charset=utf-8;base64,{{this.base64BackupCode}}"
              @icon="download"
            />
          </div>
        </div>
      </div>
    
  */
  {
    "id": "rcvm3HRP",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"backup-codes\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"wrapper\"],[12],[1,\"\\n        \"],[11,\"textarea\"],[24,1,\"backupCodes\"],[24,0,\"backup-codes-area\"],[24,\"rows\",\"10\"],[24,\"readonly\",\"\"],[4,[32,0],[[30,0,[\"registerBackupCodesArea\"]]],null],[4,[32,1],[\"click\",[30,0,[\"_selectAllBackupCodes\"]]],null],[12],[1,[30,0,[\"formattedBackupCodes\"]]],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,2],[[24,0,\"backup-codes-copy-btn\"]],[[\"@action\",\"@icon\",\"@ariaLabel\",\"@title\"],[[30,0,[\"copyToClipboard\"]],\"copy\",\"user.second_factor_backup.copy_to_clipboard\",\"user.second_factor_backup.copy_to_clipboard\"]],null],[1,\"\\n\\n          \"],[8,[32,2],[[16,\"download\",[29,[[30,0,[\"siteTitleSlug\"]],\"-backup-codes.txt\"]]],[24,0,\"backup-codes-download-btn\"],[16,\"aria-label\",[28,[32,3],[\"user.second_factor_backup.download_backup_codes\"],null]],[16,\"title\",[28,[32,3],[\"user.second_factor_backup.download_backup_codes\"],null]],[24,\"rel\",\"noopener noreferrer\"],[24,\"target\",\"_blank\"]],[[\"@href\",\"@icon\"],[[29,[\"data:application/octet-stream;charset=utf-8;base64,\",[30,0,[\"base64BackupCode\"]]]],\"download\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/backup-codes.js",
    "scope": () => [didInsert, on, DButton, i18n],
    "isStrictMode": true
  }), this))();
}