import { escapeExpression } from "discourse/lib/utilities";
function renderOption(_ref) {
  let {
    src,
    code,
    label
  } = _ref;
  const content = src ? `<img src="${escapeExpression(src)}" class="emoji">` + `<span class='emoji-shortname'>${escapeExpression(code)}</span>` : escapeExpression(label);
  return `<li><a href>${content}</a></li>`;
}
export default function renderEmojiAutocomplete(_ref2) {
  let {
    options
  } = _ref2;
  return `
    <div class='autocomplete ac-emoji'>
      <ul>
        ${options.map(renderOption).join("")}
      </ul>
    </div>
  `;
}